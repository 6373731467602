export type RouterProps = {
  path: string
  element: any
  props?: any
}

export const ROUTER = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT: '/forgot-password',

  HOME_PAGE: '/',
  PRODUCT_DETAIL_SCREEN: '/product/:slug',
  PRODUCT: '/product',
  SEARCH_HOME: '/search',
  SEARCH_RESULT: '/search-result',

  CART: '/gio-hang',
  CART_VOUCHER: '/gio-hang/voucher',

  CREATE_ORDER: '/user/create-order',
  ORDER_RESULT: '/user/order-result',
  ORDER: '/user/order',
  ORDER_DETAIL: '/user/order/:id',
  ORDER_CANCEL_REQUEST: '/user/order/cancel-request',
  ORDER_CANCEL_REQUEST_DETAIL: '/user/order/cancel-request/:id',
  ORDER_RETURN_REQUEST: '/user/order/return-request',
  ORDER_RETURN_REQUEST_DETAIL: '/user/order/return-request/:id',

  USER: '/user',
  USER_REGULAR_SCHEDULE: '/user/regular-schedule',
  USER_REGULAR_DETAIL: '/user/regular-schedule/:id',
  USER_REGULAR_EDIT_ITEM: '/user/regular-schedule/edit',
  USER_ACCOUNT: '/user/account',
  USER_PRODUCT: '/user/product',
  USER_REVIEW: '/user/review',
  USER_REVIEW_DETAIL: '/user/review/:id',
  USER_PAYMENT_METHOD: '/user/payment-method',
  USER_PAYMENT_HISTORY: '/user/payment-history',
  USER_ADDRESS: '/user/address',
  USER_VOUCHER: '/user/voucher',
  USER_POINT: '/user/omi-point',
  USER_NOTIFY: '/user/notify',
  USER_NOTIFY_TAB: '/user/notify/:tab',

  CATEGORY: '/category',
  CATEGORY_PRODUCT: '/category/:slug',
  CATEGORY_DETAIL: '/category/detail',
  BRANDING: '/thuong-hieu',
  BRAND_DETAIL: '/thuong-hieu/:slug',
  DRUG_SEARCH: '/tim-kiem/thuoc',
  DRUG_KNOWLEDGE: '/kien-thuc-ve-thuoc',
  DRUG_KNOWLEDGE_DETAIL: '/kien-thuc-ve-thuoc/chi-tiet/:group/:item',

  POLICY: '/policy',
  POLICY_DETAIL: '/policy/:slug',
  DELIVERY_POLICY: '/chinh-sach-giao-hang',
  EXCHANGE_POLICY: '/chinh-sach-doi-tra',
  PRIVATE_POLICY: '/chinh-sach-bao-mat',
  OMIPOINT_POLICY: '/chinh-sach-tich-diem-omiid',
  SERVICE_TERM: '/dieu-khoan-dich-vu',
  ORDERING_GUILD: '/huong-dan-dat-hang',

  PROMOTION: '/promotion',

  NEWS: '/tin-tuc',
  NEWS_DETAIL: '/tin-tuc/:slug',

  NEWS_REVIEW_SCREEN: '/news-review',
  NEWS_REVIEW_DETAIL_SCREEN: '/news-review/:slug',

  PRESCRIPTION: '/ca-nhan/danh-sach-don-thuoc',
  PRESCRIPTION_DETAIL: '/ca-nhan/danh-sach-don-thuoc/:id',
  POST_PRESCRIPTION: '/mua-thuoc-theo-don',
  POST_PRESCRIPTION_IMG: '/mua-thuoc-theo-don/anh-don-thuoc',
  ENTER_PRESCRIPTION: '/mua-thuoc-theo-don/nhap-don-thuoc',
  NO_PRESCRIPTION: '/mua-thuoc-theo-don/chua-co-don',

  BUY_WITH_PRESCRIPTION: '/buy-with-prescription',

  ADDRESS_LIST: '/user/address',
  ADDRESS_DETAIL: '/user/address/:id',
  EDIT_ADDRESS: '/user/address/edit',
  ADD_ADDRESS: '/user/address/add',

  HEALTH: '/suc-khoe',
  HEALTH_CATEGORY: '/suc-khoe/category',
  HEALTH_POST: '/bai-viet',
  HEALTH_POST_DETAIL: '/bai-viet/:slug',

  TAG_PAGE: '/tag',
  TAG_PAGE_DETAIL: '/tag/:slug',

  CAMPAIGN: '/campaign',
  CAMPAIGN_DETAIL: '/campaign/:slug',

  GIFT: '/gift',
  GIFT_LIST: '/gift/list',
  GIFT_DETAIL: '/gift/detail',
  GIFT_DETAIL_ITEM: '/gift/detail/:id',

  INTRODUCE: '/instroduce',
  CONTACT: '/contact',
  ACCOUNT_INFO: '/account',
  ORDER_STATUS: '/order/:idStatus',

  ABOUT_OMI_PHARMA: '/gioi-thieu',
  NEWS_EVENT: '/tin-tuc-su-kien',
  NEWS_EVENT_POST_DETAIL: '/tin-tuc-su-kien/:slug',

  STORE_OMI_PHARMA: '/store',

  PREVIEW_PRODUCT: '/preview/product/:slug',
  PREVIEW_NEWS: '/preview/news/:slug',
  PREVIEW_NEWS_REVIEW: '/preview/news-review/:slug',
  PREVIEW_HEALTH: '/preview/health/:slug',

  REGULAR_CONFIRM_SCREEN: '/regular-confirm',
  PRODUCT_HIGHLIGHT: '/product-highlight',
  ABOUT_SUGI: '/about-sugi',
  ABOUT_S_SELECT: '/about-s-select',
  BRAND_PRIECLAT_U: '/Prieclat-U',
  BRAND_S_SELECT: '/S-Select',
  BRAND_PRIECLAT: '/PriEclat',
  BRAND_T3: '/T3',
  SITEMAP: '/sitemap',
  ABOUTTHISWEBSITE: '/about-this-website',
  FAQ: '/faq',
}
