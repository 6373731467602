import sp1 from '../assets/icons/HomeScreen/sp1.png'
import ic_dec_gift from '../assets/icons/PersonalPoint/ic_dec_gift.svg'
import ic_dec_pay from '../assets/icons/PersonalPoint/ic_dec_pay.svg'
import ic_dec_voucher from '../assets/icons/PersonalPoint/ic_dec_voucher.svg'
import ic_inc_birth from '../assets/icons/PersonalPoint/ic_inc_birth.svg'
import ic_inc_buy from '../assets/icons/PersonalPoint/ic_inc_buy.svg'
import ic_inc_gift from '../assets/icons/PersonalPoint/ic_inc_gift.svg'
import banner1 from '../assets/img/Brand/banner1.png'
import img_rank1 from '../assets/img/OmiPoint/img_rank1.svg'
import img_rank2 from '../assets/img/OmiPoint/img_rank2.svg'
import img_rank3 from '../assets/img/OmiPoint/img_rank3.svg'
import img_rank4 from '../assets/img/OmiPoint/img_rank4.svg'
import post_item from '../assets/img/Post/post-item.png'
import item_1 from '../assets/img/category/item_1.png'
import ic_menu1 from './../assets/icons/HomeScreen/ic_menu1.png'
import ic_menu10 from './../assets/icons/HomeScreen/ic_menu10.png'
import ic_menu11 from './../assets/icons/HomeScreen/ic_menu11.png'
import ic_menu2 from './../assets/icons/HomeScreen/ic_menu2.png'
import ic_menu3 from './../assets/icons/HomeScreen/ic_menu3.png'
import ic_menu4 from './../assets/icons/HomeScreen/ic_menu4.png'
// import ic_menu5 from './../assets/icons/HomeScreen/ic_menu5.png'
import ic_menu6 from './../assets/icons/HomeScreen/ic_menu6.png'
import ic_menu7 from './../assets/icons/HomeScreen/ic_menu7.png'
import ic_menu8 from './../assets/icons/HomeScreen/ic_menu8.png'
import ic_menu9 from './../assets/icons/HomeScreen/ic_menu9.png'
import ic_menu17 from './../assets/icons/HomeScreen/ic_menu17.png'
import ic_menu18 from './../assets/icons/HomeScreen/ic_menu18.png'
import ic_menu19 from './../assets/icons/HomeScreen/ic_menu19.png'
import ic_menu20 from './../assets/icons/HomeScreen/ic_menu20.png'

import { ROUTER } from '../router/config'
import { MenuItemType, ProductDetailType, SelectBoxType } from '../types'

export const KEY_AUTH = 'TOKEN_OMIPHARMA'
export const KEY_USER = 'USER_OMIPHARMA'
export const KEY_REMEMBER = 'REMEMBER_OMIPHARMA'
export const hotline = process.env.REACT_APP_HOTLINE
export const diabetesKnowledge = process.env.REACT_APP_DIABETES_KNOWLEDGE_LINK
export const recruitmentKnowledge = process.env.REACT_APP_RECRUITMENT_LINK
export const customerLink = process.env.REACT_APP_CUSTOMER_MAIL
export const facebookLink = process.env.REACT_APP_FACEBOOK_LINK
export const languageVI = 'vi'
export const languageJA = 'jp'
export const languageEN = 'en'
export const language = {
  en: {
    id: languageEN,
    name: languageEN.toUpperCase(),
  },
  jp: {
    id: languageJA,
    name: languageJA.toUpperCase(),
  },
}

export const optionGender = [
  { value: 1, title: 'male' },
  { value: 2, title: 'female' },
]

export const userAccountTab = {
  account: {
    id: 1,
    name: 'userAccount.accountInfo',
  },
  user: {
    id: 2,
    name: 'userAccount.personalInfo',
  },
}

export const productOptionList = {
  favoriteProduct: {
    id: 1,
    name: 'userProduct.favoriteProduct',
  },
  buyWithDeal: {
    id: 2,
    name: 'userProduct.buyWithDeal',
  },
  needToBuyCategory: {
    id: 3,
    name: 'userProduct.needToBuy',
  },
  viewedProduct: {
    id: 4,
    name: 'userProduct.seenProduct',
  },
}

export const giftListSortData = [
  {
    order_by: 'created_at',
    order_sort: 'desc',
  },
  {
    order_by: 'conversion_value',
    order_sort: 'asc',
  },
  {
    order_by: 'conversion_value',
    order_sort: 'desc',
  },
]

export const omiPointRank = [
  {
    id: 1,
    icon: img_rank1,
    title: 'omiPoint.standardRank',
    name: 'omiPoint.friend',
    color: '#AA6F5C',
  },
  {
    id: 2,
    icon: img_rank2,
    title: 'omiPoint.goldRank',
    name: 'omiPoint.closeFriend',
    color: '#FBC654',
  },
  {
    id: 3,
    icon: img_rank3,
    title: 'omiPoint.platinumRank',
    name: 'omiPoint.companion',
    color: '#ABD0D5FF',
  },
  {
    id: 4,
    icon: img_rank4,
    title: 'omiPoint.diamondRank',
    name: 'omiPoint.soulmate',
    color: '#abd0d5',
  },
]

export const drugKnowledge = [
  {
    groupkey: '3-luu-y-ve-thuoc',
    label: 'drugKnowledge.threeNoteAboutDrug',
    itemList: [
      {
        id: 1,
        label: 'drugKnowledge.drugType',
        link: 'cac-loai-thuoc',
        datakey: 'clt',
      },
      {
        id: 2,
        label: 'drugKnowledge.mechanismAction',
        link: 'co-che-hoat-dong',
        datakey: 'cchd',
      },
      {
        id: 3,
        label: 'drugKnowledge.sideEffects',
        link: 'tac-dung-phu',
        datakey: 'tdp',
      },
    ],
  },
  {
    groupkey: 'su-dung-thuoc-hop-ly',
    label: 'drugKnowledge.howToUseDrug',
    itemList: [
      {
        id: 1,
        label: 'drugKnowledge.useProperly',
        link: 'dung-thuoc-dung-cach',
        datakey: 'dtdc',
      },
      {
        id: 2,
        label: 'drugKnowledge.objectUse',
        link: 'doi-tuong-su-dung-thuoc',
        datakey: 'dtsdt',
      },
      {
        id: 3,
        label: 'drugKnowledge.drugInteractions',
        link: 'tuong-tac-thuoc',
        datakey: 'ttt',
      },
    ],
  },
  {
    groupkey: 'cac-loai-thuoc-can-thiet',
    label: 'drugKnowledge.drugType',
    itemList: [
      {
        id: 1,
        label: 'drugKnowledge.otcDrug',
        link: 'otc-thuoc',
        datakey: 'totc',
      },
      {
        id: 2,
        label: 'drugKnowledge.otherDrugs',
        link: 'cac-loai-thuoc-khac',
        datakey: 'cltk',
      },
    ],
  },
]

export const omiPointType = [
  { id: 1, icon: ic_inc_buy },
  { id: 2, icon: ic_dec_gift },
  { id: 3, icon: ic_dec_voucher },
  { id: 4, icon: ic_inc_gift },
  { id: 5, icon: ic_dec_pay },
  { id: 6, icon: ic_inc_birth },
]

export const newMenu: MenuItemType[] = [
/*   {
    id: 1,
    name: 'navBar.productCategory',
    link: ROUTER.CATEGORY,
    icon: ic_menu1,
    slug: '',
    subs: [],
  },
  {
    id: 2,
    name: 'navBar.myReview',
    link: ROUTER.USER_REVIEW,
    icon: ic_menu2,
    slug: '',
    subs: [],
  },
  {
    id: 3,
    name: 'navBar.myOrder',
    link: ROUTER.ORDER,
    icon: ic_menu3,
    slug: '',
    subs: [],
  },
  {
    id: 4,
    name: 'navBar.regularList',
    link: ROUTER.USER_REGULAR_SCHEDULE,
    icon: ic_menu4,
    slug: '',
    subs: [],
  }, */
  // {
  //   id: 5,
  //   name: 'navBar.service',
  //   link: '',
  //   icon: ic_menu5,
  //   slug: '',
  //   subs: [
  //     { id: 0, name: 'navBar.onlineMedicalExam', link: '', slug: '', subs: [], icon: '' },
  //     { id: 1, name: 'navBar.familyPharmacist', link: '', slug: '', subs: [], icon: '' },
  //     { id: 2, name: 'navBar.personalPharmacist', link: '', slug: '', subs: [], icon: '' },
  //   ],
  // },
  // {
  //   id: 6,
  //   name: 'navBar.drugSearch',
  //   link: ROUTER.DRUG_SEARCH,
  //   icon: ic_menu6,
  //   slug: '',
  //   subs: [],
  // },
/*   {
    id: 7,
    name: 'navBar.aboutOmi',
    link: '',
    icon: ic_menu7,
    slug: '',
    subs: [
      { id: 0, name: 'navBar.aboutOmi', link: ROUTER.ABOUT_OMI_PHARMA, slug: '', subs: [], icon: '' },
      { id: 1, name: 'navBar.newsAndEvent', link: ROUTER.NEWS_EVENT, slug: '', subs: [], icon: '' },
    ],
  },
  {
    id: 8,
    name: 'navBar.healthy',
    link: ROUTER.HEALTH,
    icon: ic_menu8,
    slug: '',
    subs: [],
  },
  {
    id: 9,
    name: 'navBar.drugKnowledge',
    link: ROUTER.DRUG_KNOWLEDGE,
    icon: ic_menu9,
    slug: '',
    subs: [],
  },
  {
    id: 10,
    name: 'navBar.diabetesKnowledge',
    link: diabetesKnowledge,
    icon: ic_menu10,
    slug: '',
    subs: [],
  },
  {
    id: 11,
    name: 'navBar.omiPolicy',
    link: ROUTER.POLICY,
    icon: ic_menu11,
    slug: '',
    subs: [],
  }, */
  {
    id: 17,
    name: 'navBar.aboutSugi',
    link: ROUTER.ABOUT_SUGI,
    icon: ic_menu17,
    slug: '',
    subs: [],
  },
  {
    id: 18,
    name: 'navBar.aboutSSelectProducts',
    link: ROUTER.ABOUT_S_SELECT,
    icon: ic_menu18,
    slug: '',
    subs: [],
  },
  {
    id: 19,
    name: 'navBar.termsAndConditions',
    link: ROUTER.POLICY,
    icon: ic_menu19,
    slug: '',
    subs: [],
  },
  {
    id: 20,
    name: 'navBar.contact',
    link: ROUTER.CONTACT,
    icon: ic_menu20,
    slug: '',
    subs: [],
  },
]

export const leftLinkGroup = [
  {
    id: 1,
    title: 'footer.omiPharma',
    links: [
      { id: 1, title: 'footer.introduce', link: ROUTER.INTRODUCE },
      { id: 2, title: 'footer.recruitment', link: recruitmentKnowledge },
      { id: 3, title: 'footer.contact', link: ROUTER.CONTACT },
    ],
  },
  {
    id: 2,
    title: 'footer.community',
    links: [
      { id: 1, title: 'footer.news', link: ROUTER.NEWS },
      { id: 2, title: 'footer.healthKnowledge', link: ROUTER.HEALTH },
      { id: 3, title: 'footer.diabetesKnowledge', link: diabetesKnowledge },
    ],
  },
]

export const rightLinkGroup = [
  {
    id: 1,
    title: 'footer.account',
    links: [
      { id: 1, title: 'footer.accountInfo', link: ROUTER.ACCOUNT_INFO },
      { id: 2, title: 'footer.manageOrder', link: ROUTER.ORDER },
      { id: 3, title: 'footer.manageAddress', link: ROUTER.ADDRESS_LIST },
    ],
  },
  {
    id: 2,
    title: 'footer.custometSupport',
    links: [
      { id: 1, title: 'footer.deliveryPolicy', link: ROUTER.DELIVERY_POLICY },
      { id: 2, title: 'footer.exchangePolicy', link: ROUTER.EXCHANGE_POLICY },
      { id: 3, title: 'footer.privatePolicy', link: ROUTER.PRIVATE_POLICY },
      // { id: 4, title: 'footer.omiPolicy', link: ROUTER.OMIPOINT_POLICY },
      { id: 5, title: 'footer.serviceTerm', link: ROUTER.SERVICE_TERM },
      { id: 6, title: 'footer.serviceTerm', link: ROUTER.ORDERING_GUILD },
    ],
  },
]

export const contacts = [
  { id: 3, icon: 'fas fa-phone-volume', content: hotline, link: `tel:${hotline}`, className: 'text-primary' },
  {
    id: 4,
    icon: 'fas fa-envelope-square',
    content: customerLink,
    link: `mailto:${customerLink}`,
    className: 'text-primary',
  },
  {
    id: 5,
    icon: 'fab fa-facebook-square',
    content: 'footer.fanpageOmi',
    link: facebookLink,
    className: 'text-primary',
  },
]

export const userReviewCategory = {
  order: {
    name: 'userReviewDetail.product',
    id: 'order',
  },
  pharmacist: {
    name: 'userReviewDetail.consult',
    id: 'pharmacist',
  },
  // shop: {
  //   name: 'Cửa hàng',
  //   id: 'shop',
  // },
  service: {
    name: 'userReviewDetail.service',
    id: 'service',
  },
}

// fake data
// home page
export const pointInfo = {
  card: {
    card_code: '12313231',
    id: '12313231',
    name: 'Hạng vàng',
    point: 5000,
  },
  higher_card: {
    card_code: '12313231',
    id: '12313231',
    name: 'Hạng bạch kim',
    point: 6500,
  },
  point: {
    date_start: '2015-10-11',
    point: 5547,
    point_in_year: 2238,
  },
  nextRankSpending: 300000,
}

export const voucherList = [
  {
    id: '123abc',
    title: 'Giảm 10k',
    description: 'cho đơn hàng 149k',
  },
  {
    id: '124abc',
    title: 'Giảm 11k',
    description: 'cho đơn hàng 149k',
  },
  {
    id: '123abvc',
    title: 'Giảm 12k',
    description: 'cho đơn hàng 149k',
  },
  {
    id: '123abdc',
    title: 'Giảm 13k',
    description: 'cho đơn hàng 149k',
  },
  {
    id: '123abmc',
    title: 'Giảm 14k',
    description: 'cho đơn hàng 149k',
  },
  {
    id: '125abmc',
    title: 'Giảm 15k',
    description: 'cho đơn hàng 149k',
  },
]

export const dataCampaignFake: any[] = [
  {
    banner: banner1,
    banner_caption: 'Banner Caption',
    banner_url: banner1,
    created_at: '2021-01-25T09:59:17.000000Z',
    discount: 0,
    end_time: null,
    high_light: true,
    id: 199,
    order: 100000,
    parent_id: null,
    partner_id: '1,14',
    type_campaign: 99,
    show_home_page: 1,
    slug: 'danh-cho-ominexter',
    start_time: null,
    status: 1,
    title: 'HAPPY HOURS',
    updated_at: '2021-01-25T10:04:30.000000Z',
    products: [
      {
        id: '0',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        mainImg: sp1,
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3.5,
        sold: 1000,
        deal_type: 0,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          price: 179000,
          img: 'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '1',
        slug: 'meiji-1-3-years-old-growing-up-formula',
        mainImg: sp1,
        isRegular: true,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        sold: 1000,
        rating: 3.5,
        deal_type: 1,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '2',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        mainImg: sp1,
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3,
        sold: 1000,
        deal_type: 2,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '3',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        mainImg: sp1,
        isRegular: true,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3.5,
        sold: 1000,
        deal_type: 3,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '4',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        mainImg: sp1,
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 4,
        sold: 1000,
        deal_type: 0,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '5',
        slug: 'meiji-1-3-years-old-growing-up-formula',
        mainImg: sp1,
        isRegular: true,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 2.5,
        sold: 1000,
        deal_type: 0,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '6',
        slug: 'meiji-1-3-years-old-growing-up-formula',
        mainImg: sp1,
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 1.5,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '7',
        slug: 'meiji-1-3-years-old-growing-up-formula',
        mainImg: sp1,
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3.5,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '8',
        slug: 'meiji-1-3-years-old-growing-up-formula',
        mainImg: sp1,
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3.5,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '9',
        mainImg: sp1,
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3.5,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
    ],
  },

  {
    banner: null,
    banner_caption: null,
    banner_url: null,
    created_at: '2021-01-25T09:59:17.000000Z',
    discount: 0,
    end_time: null,
    high_light: true,
    id: 199,
    order: 100000,
    parent_id: null,
    partner_id: null,
    type_campaign: 66,
    show_home_page: 1,
    slug: 'chuong-trinh-khuyen-mai',
    start_time: null,
    status: 1,
    title: 'Chương trình khuyến mại',
    updated_at: '2021-01-25T10:04:30.000000Z',
    products: [
      {
        id: '0',
        slug: 'meiji-1-3-years-old-growing-up-formula',
        mainImg:
          'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3.5,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          price: 179000,
          img: 'https://i.ibb.co/7Yf3LNQ/img.png',
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '1',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        mainImg:
          'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
        isRegular: true,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        sold: 1000,
        rating: 3.5,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://i.ibb.co/7Yf3LNQ/img.png',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '2',
        mainImg:
          'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://i.ibb.co/7Yf3LNQ/img.png',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '3',
        mainImg:
          'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
        isRegular: true,
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3.5,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://i.ibb.co/7Yf3LNQ/img.png',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '4',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        mainImg:
          'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 4,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://i.ibb.co/7Yf3LNQ/img.png',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '5',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        mainImg:
          'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
        isRegular: true,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 2.5,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://i.ibb.co/7Yf3LNQ/img.png',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '6',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        mainImg:
          'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 1.5,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://i.ibb.co/7Yf3LNQ/img.png',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '7',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        mainImg:
          'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3.5,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://i.ibb.co/7Yf3LNQ/img.png',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '8',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        mainImg:
          'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3.5,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://i.ibb.co/7Yf3LNQ/img.png',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
      {
        id: '9',
        slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
        mainImg:
          'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
        isRegular: false,
        name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
        isDrug: false,
        isFavorite: false,
        save: 30000,
        rating: 3.5,
        sold: 1000,
        mainProductType: {
          id: '124abc',
          name: 'Lọ 39 viên',
          img: 'https://i.ibb.co/7Yf3LNQ/img.png',
          price: 179000,
        },
        discountPercent: 50,
        discountPrice: 159500,
        originalPrice: 319000,
      },
    ],
  },
]

// user fake data

export const fakeProductList: ProductDetailType[] = [
  {
    id: '0',
    code: 'XjRTRpDFHIFe0luiw9ONrWRlXVl3h26d1JyXiksnetrXk2nAZv',
    slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
    mainImg:
      'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
    isRegular: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      price: 179000,
      img: 'https://i.ibb.co/7Yf3LNQ/img.png',
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '1',
    code: 'XjRTRpDFHIFe0luiw9ONrWRlXVl3h26d1JyXiksnetrXk2nAZv',
    slug: 'meiji-1-3-years-old-growing-up-formula',
    mainImg:
      'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
    isRegular: true,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    sold: 1000,
    rating: 3.5,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      img: 'https://i.ibb.co/7Yf3LNQ/img.png',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '2',
    code: 'XjRTRpDFHIFe0luiw9ONrWRlXVl3h26d1JyXiksnetrXk2nAZv',
    slug: 'meiji-1-3-years-old-growing-up-formula',
    mainImg:
      'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
    isRegular: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      img: 'https://i.ibb.co/7Yf3LNQ/img.png',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '3',
    code: 'XjRTRpDFHIFe0luiw9ONrWRlXVl3h26d1JyXiksnetrXk2nAZv',
    slug: 'meiji-1-3-years-old-growing-up-formula',
    mainImg:
      'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
    isRegular: true,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      img: 'https://i.ibb.co/7Yf3LNQ/img.png',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '4',
    code: 'XjRTRpDFHIFe0luiw9ONrWRlXVl3h26d1JyXiksnetrXk2nAZv',
    slug: 'meiji-1-3-years-old-growing-up-formula',
    mainImg:
      'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
    isRegular: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 4,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      img: 'https://i.ibb.co/7Yf3LNQ/img.png',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '5',
    code: 'XjRTRpDFHIFe0luiw9ONrWRlXVl3h26d1JyXiksnetrXk2nAZv',
    slug: 'meiji-1-3-years-old-growing-up-formula',
    mainImg:
      'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
    isRegular: true,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 2.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      img: 'https://i.ibb.co/7Yf3LNQ/img.png',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '6',
    slug: 'sua-tam-shikioriori-soy-milk-body-soap-tinh-chat-dau-nanh-nhat-ban-chai-600ml',
    code: 'XjRTRpDFHIFe0luiw9ONrWRlXVl3h26d1JyXiksnetrXk2nAZv',
    mainImg:
      'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
    isRegular: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 1.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      img: 'https://i.ibb.co/7Yf3LNQ/img.png',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '7',
    code: 'XjRTRpDFHIFe0luiw9ONrWRlXVl3h26d1JyXiksnetrXk2nAZv',
    slug: 'meiji-1-3-years-old-growing-up-formula',
    mainImg:
      'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
    isRegular: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      img: 'https://i.ibb.co/7Yf3LNQ/img.png',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '8',
    code: 'XjRTRpDFHIFe0luiw9ONrWRlXVl3h26d1JyXiksnetrXk2nAZv',
    slug: 'meiji-1-3-years-old-growing-up-formula',
    mainImg:
      'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
    isRegular: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      img: 'https://i.ibb.co/7Yf3LNQ/img.png',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '9',
    code: 'XjRTRpDFHIFe0luiw9ONrWRlXVl3h26d1JyXiksnetrXk2nAZv',
    slug: 'meiji-1-3-years-old-growing-up-formula',
    mainImg:
      'https://static.omipharma.vn/files/product/2021-08/gel-tay-te-bao-chet-hatomugi-platinum-label-chai-300-g.jpg',
    isRegular: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      img: 'https://i.ibb.co/7Yf3LNQ/img.png',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
]

export const fakeCardType: SelectBoxType[] = [
  { id: 0, label: 'Tất cả' },
  { id: 1, label: 'Thẻ tín dụng' },
  { id: 2, label: 'Ví điện tử' },
]

export const fakeOrderType: SelectBoxType[] = [
  { id: 0, label: 'Tất cả' },
  { id: 1, label: 'Đơn mua lẻ' },
  { id: 2, label: 'Giảm giá' },
  { id: 3, label: 'Mua định kỳ' },
]

export const fakePostList = [
  {
    id: 0,
    slug: 'nha-thuoc-omi-dong-hanh',
    publish_at: '2019-01-05 14:54:21',
    thumbnail: post_item,
    title: 'NHÀ THUỐC OMI PHARMA ĐỒNG HÀNH CÙNG CƯ DÂN PARK 8 TIMES CITY QUYẾT THẮNG ĐẠI DỊCH',
    description:
      'Tháng 8 này, hoạt động tài trợ khẩu trang cho cư dân Park 8 Times City tiếp tục được Omi Pharma phối hợp triển khai với Ban Quản lý',
  },
  {
    id: '1',
    thumbnail: post_item,
    slug: 'nha-thuoc-omi-dong-hanh',
    published_at: '10-11-2015',
    date: '10-11-2015',
    title: 'NHÀ THUỐC OMI PHARMA ĐỒNG HÀNH CÙNG CƯ DÂN PARK 8 TIMES CITY QUYẾT THẮNG ĐẠI DỊCH',
    description:
      'Tháng 8 này, hoạt động tài trợ khẩu trang cho cư dân Park 8 Times City tiếp tục được Omi Pharma phối hợp triển khai với Ban Quản lý',
  },
  {
    id: '2',
    thumbnail: post_item,
    slug: 'nha-thuoc-omi-dong-hanh',
    published_at: '10-11-2015',
    date: '10-11-2015',
    title: 'NHÀ THUỐC OMI PHARMA ĐỒNG HÀNH CÙNG CƯ DÂN PARK 8 TIMES CITY QUYẾT THẮNG ĐẠI DỊCH',
    description:
      'Tháng 8 này, hoạt động tài trợ khẩu trang cho cư dân Park 8 Times City tiếp tục được Omi Pharma phối hợp triển khai với Ban Quản lý',
  },
  {
    id: 3,
    thumbnail: post_item,
    slug: 'nha-thuoc-omi-dong-hanh',
    published_at: '2019-01-05 14:54:21',
    date: '2019-01-05 14:54:21',
    title: 'NHÀ THUỐC OMI PHARMA ĐỒNG HÀNH CÙNG CƯ DÂN PARK 8 TIMES CITY QUYẾT THẮNG ĐẠI DỊCH',
    description:
      'Tháng 8 này, hoạt động tài trợ khẩu trang cho cư dân Park 8 Times City tiếp tục được Omi Pharma phối hợp triển khai với Ban Quản lý',
  },
]

export const cancelSuggestionReason = [
  {
    id: 1,
    text: 'Tôi muốn thay đổi địa chỉ',
  },
  {
    id: 2,
    text: 'Tôi muốn thay đổi voucher',
  },
  {
    id: 3,
    text: 'Tôi muốn mua thay đổi sản phẩm',
  },
  {
    id: 4,
    text: 'Tôi muốn mua thay đổi sản phẩm',
  },
]

const fakeGiftDataItem = {
  gift_id: 123131,
  code: '23123123',
  name: 'Gift ',
  title: 1,
  end_date: '10/11/2023',
  about_expire: '10/11/2023',
  total: 3,
  point: 200,
  image: item_1,
}

export const fakeGiftData = [
  fakeGiftDataItem,
  {
    ...fakeGiftDataItem,
    title: 2,
  },
  {
    ...fakeGiftDataItem,
    title: 3,
  },
  {
    ...fakeGiftDataItem,
    title: 4,
  },
  {
    ...fakeGiftDataItem,
  },
  {
    ...fakeGiftDataItem,
  },
  {
    ...fakeGiftDataItem,
  },
]

export const listCategories = [
    {
        id: 1,
        name: 'category.healthCare',
        slug: 'health-care',
    },
    {
        id: 2,
        name: 'category.personalCare',
        slug: 'bathpersonal-care',
    },
    {
        id: 3,
        name: 'category.beautyCare',
        slug: 'beauty',
    },
    {
        id: 4,
        name: 'category.mamaBaby',
        slug: 'mama-baby',
    },
    {
        id: 5,
        name: 'category.grocery',
        slug: 'grocery',
    },
    {
        id: 6,
        name: 'category.homeCare',
        slug: 'homecare',
    },
]