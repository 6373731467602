import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import { axiosBaseQuery } from '../../hooks'
import { BrandVideoType } from '../../../types/brandVideoType'

export const brandVideoApi = createApi({
  reducerPath: 'brandVideoApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getBrandVideoList: build.query<BrandVideoType[] | undefined, unknown>({
        query: () => ({
          url: network.brandVideo.getList,
          method: 'get',
        }),
        transformResponse: (rawResult: ResponseData<BrandVideoType[]>) => {
          return rawResult.data
        },
    }),
  }),
})

export const { useGetBrandVideoListQuery } = brandVideoApi

export default brandVideoApi
