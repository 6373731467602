import jpgDefault from '../assets/img/default.jpg'

export function chunk(input: Array<any>, chunkSize: number): Array<any> {
  const result = []
  let i, j, temparray
  const chunk = chunkSize
  for (i = 0, j = input.length; i < j; i += chunk) {
    temparray = input.slice(i, i + chunk)
    result.push(temparray)
  }

  return result
}

export function buildQuery(data: any): string {
  if (typeof data !== 'object') {
    return ''
  }

  const queries: any = []
  Object.keys(data).map((k) => {
    queries.push(k + '=' + encodeURIComponent(data[k]))
  })
  return queries.join('&')
}

export function copy(src: any, target: any) {
  if (!target) {
    target = Array.isArray(src) ? [] : {}
  }

  Object.keys(src).map((k) => {
    target[k] = src[k]
  })

  return target
}

export function getUrlWithQuery(uri: string, query: any = {}): string {
  const [path, search] = uri.split('?')
  const current = parseQuery(search)
  copy(query, current)

  if (Object.keys(current).length) {
    return path + '?' + buildQuery(current)
  }

  return path
}

export function thumbUrl(url: string) {
  if (!url) {
    return jpgDefault
  }

  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url
  }
}

export function rand(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function arrayRand(arr: Array<any>): any {
  return arr[Math.floor(Math.random() * arr.length)]
}

export function range(len: number, step = 1, start = 0): Array<number> {
  const arr = []
  for (let i = start; i < len; i += step) {
    arr.push(i)
  }

  return arr
}

export function clone(item: any): any {
  if (!item) {
    return item
  } // null, undefined values check

  const types = [Number, String, Boolean]
  let result: any

  // normalizing primitives if someone did new String('aaa'), or new Number('444');
  types.forEach(function (type) {
    if (item instanceof type) {
      result = type(item)
    }
  })

  if (typeof result == 'undefined') {
    if (Object.prototype.toString.call(item) === '[object Array]') {
      result = []
      item.forEach(function (child: any, index: number) {
        result[index] = clone(child)
      })
    } else if (typeof item == 'object') {
      // testing that this is DOM
      if (item.nodeType && typeof item.cloneNode == 'function') {
        result = item.cloneNode(true)
      } else if (!item.prototype) {
        // check that this is a literal
        if (item instanceof Date) {
          result = new Date(item)
        } else {
          // it is an object literal
          result = {}
          for (const i in item) {
            result[i] = clone(item[i])
          }
        }
      } else {
        // depending what you would like here,
        // just keep the reference, or create new object

        result = item
      }
    } else {
      result = item
    }
  }

  return result
}

export function stringTruncate(str: string, length: number) {
  if (!str) {
    return ''
  }

  const dots = str.length > length ? '...' : ''
  return str.substring(0, length) + dots
}

export const debounce = (func: any, timeout = 300) => {
  let timer: any
  return (...args: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export function isStringNullOrWhiteSpace(value: any) {
  if (value == null) return true

  return value.replace(/\s/g, '').length === 0
}

export function parseQuery(queryString: any) {
  const query: any = {}

  if (!queryString) {
    return query
  }

  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

export function isEmpty(obj: any): boolean {
  if (!obj) {
    return true
  }

  for (const k in obj) {
    return false
  }

  return true
}

export function ucfirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function stringFormat(str: any, args: Array<number | string>) {
  return str.replace(/{(\d+)}/g, function (match: any, number: any) {
    return typeof args[number] != 'undefined' ? args[number] : match
  })
}

export function rtrim(str: string, charlist: string): string {
  //  discuss at: https://locutus.io/php/rtrim/
  // original by: Kevin van Zonneveld (https://kvz.io)
  //    input by: Erkekjetter
  //    input by: rem
  // improved by: Kevin van Zonneveld (https://kvz.io)
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  // bugfixed by: Brett Zamir (https://brett-zamir.me)
  //   example 1: rtrim('    Kevin van Zonneveld    ')
  //   returns 1: '    Kevin van Zonneveld'

  charlist = !charlist ? ' \\s\u00A0' : (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '\\$1')

  const re = new RegExp('[' + charlist + ']+$', 'g')

  return (str + '').replace(re, '')
}

export function ltrim(str: string, charlist: string): string {
  //  discuss at: https://locutus.io/php/ltrim/
  // original by: Kevin van Zonneveld (https://kvz.io)
  //    input by: Erkekjetter
  // improved by: Kevin van Zonneveld (https://kvz.io)
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  //   example 1: ltrim('    Kevin van Zonneveld    ')
  //   returns 1: 'Kevin van Zonneveld    '

  charlist = !charlist ? ' \\s\u00A0' : (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '$1')

  const re = new RegExp('^[' + charlist + ']+', 'g')

  return (str + '').replace(re, '')
}

export function trim(str: string, charlist: string): string {
  //  discuss at: https://locutus.io/php/trim/
  // original by: Kevin van Zonneveld (https://kvz.io)
  // improved by: mdsjack (https://www.mdsjack.bo.it)
  // improved by: Alexander Ermolaev (https://snippets.dzone.com/user/AlexanderErmolaev)
  // improved by: Kevin van Zonneveld (https://kvz.io)
  // improved by: Steven Levithan (https://blog.stevenlevithan.com)
  // improved by: Jack
  //    input by: Erkekjetter
  //    input by: DxGx
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  //   example 1: trim('    Kevin van Zonneveld    ')
  //   returns 1: 'Kevin van Zonneveld'
  //   example 2: trim('Hello World', 'Hdle')
  //   returns 2: 'o Wor'
  //   example 3: trim(16, 1)
  //   returns 3: '6'

  let whitespace = [
    ' ',
    '\n',
    '\r',
    '\t',
    '\f',
    '\x0b',
    '\xa0',
    '\u2000',
    '\u2001',
    '\u2002',
    '\u2003',
    '\u2004',
    '\u2005',
    '\u2006',
    '\u2007',
    '\u2008',
    '\u2009',
    '\u200a',
    '\u200b',
    '\u2028',
    '\u2029',
    '\u3000',
  ].join('')
  let l = 0
  let i = 0
  str += ''

  if (charlist) {
    whitespace = (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '$1')
  }

  l = str.length
  for (i = 0; i < l; i++) {
    if (whitespace.indexOf(str.charAt(i)) === -1) {
      str = str.substring(i)
      break
    }
  }

  l = str.length
  for (i = l - 1; i >= 0; i--) {
    if (whitespace.indexOf(str.charAt(i)) === -1) {
      str = str.substring(0, i + 1)
      break
    }
  }

  return whitespace.indexOf(str.charAt(0)) === -1 ? str : ''
}

export function mb_strlen(str: any) {
  if (typeof str === 'string') {
    return str.length
  }

  return 0
}

export function arr(value: any): Array<any> {
  if (Array.isArray(value)) {
    return value
  }

  return value ? [value] : []
}

export function flip(array: Array<any>, fillValue: any = null) {
  if (!Array.isArray(array)) {
    array = arr(array)
  }

  const n = array.length
  const obj: any = {}
  if (fillValue) {
    for (let i = 0; i < n; i++) {
      obj[array[i]] = fillValue
    }
  } else {
    for (let i = 0; i < n; i++) {
      obj[array[i]] = i
    }
  }

  return obj
}

export function percentage(partialValue: any, totalValue: any): number {
  if (!totalValue) {
    return 0
  }

  return (100 * partialValue) / totalValue
}

export function sleep(miliseconds: number): Promise<boolean> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, miliseconds)
  })
}

/**
 *
 * @param arr Array
 * @param key string
 * @param value string | null
 * @returns {{}}
 */
export function pluck(arr: any, key = 'id', value: any = null) {
  if (!Array.isArray(arr)) {
    return {}
  }

  const map: any = {}
  const len = arr.length
  for (let i = 0; i < len; i++) {
    map[arr[i][key]] = value === null ? arr[i] : arr[i][value]
  }

  return map
}

export function getValidPage(page: any): number {
  const p = parseInt(page)
  if (!p) {
    return 1
  }

  if (p < 1) {
    return 1
  }

  return p
}

export function intVal(n: any): number {
  const a = parseInt(n)

  return a ? a : 0
}

export function pushLocation(url: string, navigate: any) {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    window.location.href = url
    return
  }
  navigate(url)
}

// export function throttle(func: any, wait = 500, options: any = {}) {
//   let context: any, args: any, result: any
//   let timeout: any = null
//   var previous = 0
//   if (!options) options = {}
//   var later = function () {
//     previous = options.leading === false ? 0 : Date.now()
//     timeout = null
//     result = func.apply(context, args)
//     if (!timeout) context = args = null
//   }

//   return function () {
//     var now = Date.now()
//     if (!previous && options.leading === false) previous = now
//     var remaining = wait - (now - previous)
//     // @ts-ignore
//     context = this
//     args = [...arguments]
//     if (remaining <= 0 || remaining > wait) {
//       if (timeout) {
//         clearTimeout(timeout)
//         timeout = null
//       }
//       previous = now
//       result = func.apply(context, args)
//       if (!timeout) context = args = null
//     } else if (!timeout && options.trailing !== false) {
//       timeout = setTimeout(later, remaining)
//     }
//     return result
//   }
// }

export function urlGetPath(url: string): string {
  const u = new URL(url)
  if (u.host !== window.location.host) {
    return url
  }

  return u.pathname
}

export const calculatePageNumber = (
  total: number | string | undefined,
  pageSize: number | string | undefined,
): number => {
  if (!total || !pageSize) return 1
  return Math.ceil(Number(total) / Number(pageSize))
}

export const regex = {
  email: /@[^.]*\./,
  phoneRegExp:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  phoneNumberRegExp: /^(0)+([0-9]{9})$/,
}

type CalculateParamType = {
  price: number
  discount_price?: number
  discount_percent?: number
}

export const calculatePrice = ({ price, discount_price, discount_percent }: CalculateParamType) => {
  if (discount_percent) {
    return Math.round((price * (100 - discount_percent)) / 100)
  }

  if (discount_price) {
    return price - discount_price
  }

  return price
}

export const calculateDiscountPercent = ({ price, discount_price, discount_percent }: CalculateParamType) => {
  if (discount_percent) {
    return discount_percent
  }
  if (discount_price) {
    return Math.round(100 - discount_price / price)
  }

  return 0
}

type InputFormatAddress = {
  addressDetail?: string
  wardName?: string
  districtName?: string
  provinceName?: string
}

export const formatAddress = ({ addressDetail, wardName, districtName, provinceName }: InputFormatAddress) => {
  const addressArray: Array<string> = []
  if (addressDetail) {
    addressArray.push(addressDetail)
  }
  if (wardName) {
    addressArray.push(wardName)
  }
  if (districtName) {
    addressArray.push(districtName)
  }
  if (provinceName) {
    addressArray.push(provinceName)
  }

  return addressArray.length > 0 ? addressArray.join(', ') : ''
}

export const compareLaterDate = (dateOne: string, dateTwo?: string) => {
  const date = new Date()
  const date1 = new Date(dateOne).getTime()
  const date2 = dateTwo ? new Date(dateTwo).getTime() : date.getTime()
  return date1 > date2
}

declare global {
  interface Window {
    $ChatBox?: {
      $ref: any
      hide: () => void
      show: () => void // Đảm bảo rằng phương thức show() tồn tại và có kiểu trả về là void
    }
  }
}

export const handleClickChat = () => {
  // Kiểm tra xem biến ChatBox và phương thức show() có tồn tại không
  if (window.$ChatBox && window.$ChatBox.show) {
    const tempChatBox = document.querySelector('.omi-chatbox') as any
    // Kiểm tra xem phần tử mới có tồn tại và có chiều cao lớn hơn 0 không
    if (tempChatBox) {
      tempChatBox.classList.remove('low-z-index')

      if (tempChatBox.offsetHeight < 500) return window.$ChatBox.show()

      window.$ChatBox.hide()
    }
  } else {
    console.error('Biến ChatBox hoặc phương thức show() không tồn tại.')
  }
}

export const handleCloseChat = () => {
  if (window.$ChatBox && window.$ChatBox.hide) return window.$ChatBox.hide()
}

// Định nghĩa hàm để kiểm tra và thiết lập z-index cho ChatBox
export const checkAndSetZIndex = (element: HTMLElement) => {
  if (element) {
    if (element.offsetHeight < 500) {
      element.style.zIndex = '99999'
      // element.classList.add('low-z-none')
      // element.classList.remove('low-z-block')
    } else {
      element.style.zIndex = '0'
      // element.classList.add('low-z-block')
      // element.classList.remove('low-z-none')
    }
  }
}

// Tạo một MutationObserver để theo dõi sự thay đổi của tempChatBox
export const observer = new MutationObserver((mutationsList, observer) => {
  console.log('observer', observer)

  for (const mutation of mutationsList) {
    if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
      // Kiểm tra và thiết lập z-index khi tempChatBox thay đổi
      checkAndSetZIndex(mutation.target as HTMLElement)
    }
  }
})

export const removeWord = (content: string, word: string) => {
  return content.replace(new RegExp(`\\b${word}\\b`, 'g'), '') // Xóa từ cụ thể
}

const generateId = (text: any) => {
  return text.toLowerCase().replace(/[^a-z0-9]+/g, '-')
}

export const extractHeadings = (content: any) => {
  const div = document.createElement('div')
  div.innerHTML = content

  const headings = div.querySelectorAll('h2, h3')
  const headingList = [] as any
  let currentH2 = null as any

  headings.forEach((heading, index) => {
    let id = heading.id
    if (!id) {
      id = generateId(heading.innerHTML) + '-' + index
      heading.id = id
    }
    const text = heading.textContent
    const level = heading.tagName.toLowerCase()

    if (level === 'h2') {
      currentH2 = { id, text, children: [] }
      headingList.push(currentH2)
    } else if (level === 'h3' && currentH2) {
      currentH2.children.push({ id, text })
    }
  })

  return headingList
}

export const addIdsToHeadings = (content: any) => {
  const div = document.createElement('div')
  div.innerHTML = content

  const headings = div.querySelectorAll('h2, h3')

  headings.forEach((heading, index) => {
    if (!heading.id) {
      heading.id = generateId(heading.innerHTML) + '-' + index
    }
  })

  const figures = div.querySelectorAll('figure')
  figures.forEach((figure) => {
    figure.removeAttribute('style')
  })

  return div.innerHTML
}
