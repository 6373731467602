export const API_ROOT = process.env.REACT_APP_API_URL as string
export const API_BANK = process.env.REACT_APP_BANK_API_URL as string

export const network = {
  home: {
    setting: '/pharma/setting',
  },
  store: {
    getList: '/pharma/shop',
    searchList: '/pharma/shop/search',
  },
  post: {
    getList: '/pharma/post/search',
    getItem: (slug: string | undefined) => `/pharma/post/${slug}`,
    getProductSuggest: '/pharma/post/product-suggest',
  },
  city: {
    getList: `/pharma/province`,
    getItem: (id: string | number) => `/pharma/province/${id}`,
  },
  district: {
    getList: '/pharma/district/search',
    getItem: (id: string | number) => `/pharma/district/${id}`,
  },
  ward: {
    getList: '/pharma/ward/search',
    getItem: (id: string | number) => `/pharma/ward/${id}`,
  },
  brand: {
    getList: '/pharma/brand',
    getItem: (slug: string) => '/pharma/brand/' + slug,
  },
  auth: {
    login: '/auth/login',
    verify: '/auth/verify',
    verifyCheck: '/auth/verify-check',
    register: '/auth/signup',
    loginSocial: '/auth/login-social',
    forgotPass: '/auth/forgot-pass',
    changePass: '/auth/change-pass',
    updateUser: '/auth/update-user',
    getTemToken: '/pharma/setting/get-token',
  },
  users: {
    getList: '/auth/getManyUser',
    getOne: '/auth/getUser',
    update: '/auth/updateRoleUser',
    create: '/auth/createRoleUser',
  },
  userAddress: {
    getList: '/pharma/address',
    getItem: (id: string | number) => `/pharma/address/${id}`,
    putItem: '/pharma/address/',
    addItem: '/pharma/address',
    deleteItem: '/pharma/address/',
  },
  userProductWishList: {
    getList: '/pharma/wish-list',
    getItem: '/pharma/wish-list/',
    addItem: '/pharma/wish-list',
    updateItem: '/pharma/wish-list/',
    deleteItem: '/pharma/wish-list/',
    addToItem: '/pharma/wish-list/add-item',
    deleteFromItem: '/pharma/wish-list/delete-item',
    deleteAllFromItem: '/pharma/wish-list/delete-all-item',
  },
  userProductFavoriteList: {
    getList: '/pharma/favorite/search',
    addItem: '/pharma/favorite',
    deleteItem: '/pharma/favorite',
  },
  userProductComboList: {
    getList: '/pharma/combo',
    getRelatedProducts: '/pharma/combo/related-products',
  },
  productDetail: {
    getItem: '/pharma/product/',
    getItemReview: '/pharma/product/review/',
    getSimilarList: '/pharma/product/category/',
    getSeenList: '/pharma/product/customer/viewed',
    getFlashSaleList: '/pharma/product/search',
    getCampaignList: '/pharma/product/search',
    getCategoryList: '/pharma/product/search',
    getSuggestTodayList: '/pharma/product/search',
  },
  cart: {
    getCart: '/pharma/cart',
    addItem: '/pharma/cart/add-item',
    deleteItem: '/pharma/cart/delete-item',
    updateItem: '/pharma/cart/update-item',
    updatePromotion: '/pharma/cart/update-promotion',
    selectedAll: '/pharma/cart/select-all',
    reOrder: '/pharma/order/re-order',
  },
  order: {
    orderList: '/pharma/order/search',
    createOrderDetail: '/pharma/order/',
    orderDetail: '/pharma/order/detail/',
    createOrder: '/pharma/order',
    searchOrder: '/pharma/order/search',
    updateOrder: '/pharma/order/',
    confirmOrder: '/pharma/order/confirm',
    buyNow: '/pharma/order',
    addAddressNoUser: '/pharma/address/guest',
    cancelOrder: '/pharma/order/cancel',
    returnOrder: '/pharma/order-request-return',
    getReturnReason: '/pharma/reason-template',
  },
  userReview: {
    productReview: '/pharma/review/search',
    shippingReview: '/pharma/shipping-review/search',
    consultReview: '/pharma/consult-review/search',
    deleteConsultReview: '/pharma/consult-review/',
    deleteShippingReview: '/pharma/shipping-review/',
    deleteProductReview: '/pharma/review/',
  },
  prescription: {
    createItem: '/pharma/prescription',
    getItem: '/pharma/prescription',
    getList: '/pharma/prescription/search',
    deleteItem: '/pharma/prescription',
  },
  medicine: {
    getMedicineList: '/pharma/prescription/get-list-medicine',
    searchMedicine: '/pharma/prescription/search-medicine',
  },
  orderReview: {
    reviewOrder: '/pharma/order/review',
    reviewSuggest: '/pharma/review-suggest',
  },
  searchDug: {
    search: '/pharma/product/medicine/search',
    historySearch: '/pharma/product/medicine/search/histories',
  },
  voucher: {
    getList: '/pharma/promotion',
    getItem: (id: string | number) => '/pharma/promotion/' + id,
    getListGroup: '/pharma/promotion/group',
    saveVoucher: '/pharma/promotion/customer-save',
  },
  category: {
    getList: '/pharma/category',
    searchCategory: '/pharma/category/search',
  },
  comment: {
    comment: '/pharma/comment',
    search: '/pharma/comment/search',
  },
  userNotify: {
    getList: '/pharma/notifications',
    getCertainList: '/pharma/notifications/search',
    getItem: '/pharma/notifications/',
    readAll: '/pharma/notifications/read-all',
    deleteItem: '/pharma/notifications/',
    countUnreadTotal: '/pharma/notifications/count-unread',
  },
  accumulation_history: {
    getList: '/auth/accumulation_histories',
  },
  regularSchedule: {
    getList: '/pharma/order-frequency',
    getItem: '/pharma/order-frequency/',
    updateItem: '/pharma/order-frequency/',
    deleteItem: '/pharma/order-frequency/',
    getListConfirm: '/pharma/order-frequency/get-frequency',
    updateConfirmItem: '/pharma/order-frequency/update-frequency',
  },
  homeSearch: {
    homeSearch: '/pharma/search',
    getSuggest: '/pharma/search',
    getHistorySearch: '/pharma/search/history',
  },
  bank: {
    getList: '/banks',
  },
  popup: {
    getByLang: '/pharma/popup/getByLang',
  },
  contact: {
    create: '/pharma/contact/create',
  },
  brandVideo: {
    getList: '/pharma/brandVideo/getList',
  },
}
