import { GoogleOAuthProvider } from '@react-oauth/google'
import { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './App.css'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { useGetTemTokenQuery, useGetUserQuery } from './app/services/auth'
import { setUserInfo } from './app/services/auth/auth-slice'
import { changeLang, selectLang } from './app/slice'
import './assets/css/style.scss'
import { Alert } from './components/Alert'
import { KEY_AUTH, languageEN, languageJA, languageVI } from './constant'
import { getToken } from './helpers/cookieHelper'
import { getTemToken, setTemToken } from './helpers/storage'
import './i18n/index'
import i18n from './i18n/index'
import ScrollToTop from './layouts/ScrollToTop'
import RenderRoute from './router/RenderRoute'
import { handleCloseChat, observer } from './helpers/utils'

function App() {
  const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? 'GG'
  const language = useAppSelector(selectLang)
  const dispatch = useAppDispatch()
  const [isLogin, setIslogin] = useState(false)
  const { data, isLoading } = useGetUserQuery(null, {
    skip: !isLogin,
  })
  const temToken = getTemToken()
  const { data: temTokenData } = useGetTemTokenQuery({}, { skip: !!temToken })

  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0]
    // const lang = localStorage.getItem('languageOmiPharma')
    if (language) {
      i18n.changeLanguage(language)
    }
    if (language === languageEN) {
      bodyElement.style.fontFamily = 'Archivo,sans-serif'
    }
    if (language === languageVI) {
      bodyElement.style.fontFamily = 'Arial,sans-serif,Helvetica'
    }
    if (language === languageJA) {
      bodyElement.style.fontFamily = 'Nunito,san-serif'
    }
  }, [language])

  useEffect(() => {
    if (temTokenData?.data?._token) {
      setTemToken(temTokenData.data?._token)
    }
  }, [temTokenData])

  useEffect(() => {
    if (getToken(KEY_AUTH) && !isLogin) {
      setIslogin(true)
    }
    //Check ngôn ngữ máy navigator.languages
    // const lang =
    //   localStorage.getItem('languageOmiPharma') || (navigator.languages ? navigator.languages[0] : navigator.language)
    // const defaultLang = lang.startsWith(languageJA) ? languageJA : languageEN
    // i18n.changeLanguage(defaultLang)
    // dispatch(changeLang(defaultLang))

    const tempChatBox = document.querySelector('.omi-chatbox') as HTMLElement
    if (tempChatBox) {
      // Thiết lập z-index ban đầu là 0
      tempChatBox.classList.add('low-z-index')
      // tempChatBox.classList.add('low-z-none');
      // Thiết lập đóng chat lần đầu tiên
      handleCloseChat()
      // Bắt đầu theo dõi các thay đổi của tempChatBox
      observer.observe(tempChatBox, { attributes: true, attributeFilter: ['style'] })
    }
  }, [])

  useEffect(() => {
    if (!isLoading && getToken(KEY_AUTH)) {
      dispatch(setUserInfo(data?.data))
    }
  }, [data])

  return (
    <div className='container-fluid'>
      <GoogleOAuthProvider clientId={client_id}>
        <ScrollToTop>
          <RenderRoute />
        </ScrollToTop>
        <Alert />
        <ToastContainer />
      </GoogleOAuthProvider>
    </div>
  )
}

export default App
