import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '../../hooks'
import { API_ROOT, network } from '../../../constant/network'
import {
  BriefProduct,
  GetCagetoryDto,
  GetCampaignDto,
  GetSuggestTodayDto,
  ProductDetailType,
  ProductReviewDetailDtoType,
  ProductReviewType,
  SimilarProductType,
} from '../../../types/productDetailType'
import { ResponseData } from '../../../types'

const productDetailApi = createApi({
  reducerPath: 'productDetailApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    getProductDetail: build.query<ProductDetailType | undefined, unknown>({
      query: (slug: string) => ({
        url: network.productDetail.getItem + slug,
        method: 'GET',
      }),
      transformResponse: (rawResult: ResponseData<ProductDetailType>) => {
        return rawResult.data
      },
    }),
    getProductPreview: build.query<ProductDetailType | undefined, unknown>({
      query: (params: any) => ({
        url: network.productDetail.getItem + params.slug,
        method: 'GET',
        params: params,
      }),
      transformResponse: (rawResult: ResponseData<ProductDetailType>) => {
        return rawResult.data
      },
    }),
    getProductReview: build.query<ProductReviewType | undefined, unknown>({
      query: (params: ProductReviewDetailDtoType) => ({
        url: network.productDetail.getItemReview + params.slug,
        method: 'GET',
        params: { page: params.page, pageSize: params?.pageSize || 10 },
      }),
      transformResponse: (rawResult: ResponseData<ProductReviewType>) => {
        return rawResult.data
      },
    }),
    getSimilarList: build.query<SimilarProductType[] | undefined, unknown>({
      query: (slug: string) => ({
        url: network.productDetail.getSimilarList + slug,
        method: 'GET',
      }),
      transformResponse: (rawResult: ResponseData<SimilarProductType[]>) => {
        return rawResult.data
      },
    }),
    getSeenList: build.query<SimilarProductType[] | undefined, unknown>({
      query: () => ({
        url: network.productDetail.getSeenList,
        method: 'GET',
      }),
      transformResponse: (rawResult: ResponseData<SimilarProductType[]>) => {
        return rawResult.data
      },
    }),
    getCategoryList: build.mutation<BriefProduct[] | undefined, unknown>({
      query: (params: GetCagetoryDto) => ({
        url: network.productDetail.getCategoryList,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BriefProduct[]>) => {
        return rawResult.data
      },
    }),
    getCampaignList: build.mutation<BriefProduct[] | undefined, unknown>({
      query: (params: GetCampaignDto) => ({
        url: network.productDetail.getCampaignList,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BriefProduct[]>) => {
        return rawResult.data
      },
    }),
    getSuggestTodayList: build.mutation<BriefProduct[] | undefined, unknown>({
      query: (params: GetSuggestTodayDto) => ({
        url: network.productDetail.getSuggestTodayList,
        method: 'POST',
        data: params,
      }),
      transformResponse: (rawResult: ResponseData<BriefProduct[]>) => {
        return rawResult.data
      },
    }),
    getProductByCategoryList: build.mutation<ResponseData<BriefProduct[]> | undefined, unknown>({
      query: (params: GetCagetoryDto) => ({
        url: network.productDetail.getCategoryList,
        method: 'POST',
        data: params,
      }),
    }),
  }),
})

export const {
  useGetProductDetailQuery,
  useGetProductPreviewQuery,
  useGetProductReviewQuery,
  useGetSimilarListQuery,
  useLazyGetSeenListQuery,
  useGetCampaignListMutation,
  useGetCategoryListMutation,
  useGetSuggestTodayListMutation,
  useGetProductByCategoryListMutation,
} = productDetailApi

export default productDetailApi
