import { combineReducers } from '@reduxjs/toolkit'
import { StoreReducer } from './slice'
import { HomeSearchReducer } from '../pages/homeSearch/strore/slice'
import { HomePageReducer } from '../pages/home/store/slice'
import { ProductDetailReducer } from '../pages/productDetailScreen/store/slice'
import { UserScreenReducer } from '../pages/user/store/slice'
import { OrderScreenReducer } from '../pages/order/store/slice'
import { VoucherPageReducer } from '../pages/voucher/store/slice'
import { VoucherPageCartReducer } from '../pages/cart/voucher/store/slice'
import { CartReducer } from '../pages/cart/store/slice'
import { NewsPageReducer } from '../pages/news/store/slice'
import { PostDetailReducer } from '../pages/postDetail/store/slice'
import { PrescriptionsPageReducer } from '../pages/prescription/store/slice'
import { AddressListReducer } from '../pages/addressList/store/slice'
import { AddressFormReducer } from '../pages/addressForm/store/slice'
import { AddressDetailReducer } from '../pages/addressDetail/store/slice'
import { CreatOrderReducer } from '../pages/createOrder/strore/slice'
import { CancelOrderReducer } from '../pages/cancelOrder/store/slice'
import { EditRegularItemReducer } from '../pages/editRegularItem/strore/slice'
import { OrderReviewDetailReducer } from '../pages/userReviewDetail/strore/slice'
import { TagDetailPageReducer } from '../pages/tagDetail/store/slice'
import { BuyWithPrescriptionReducer } from '../pages/buyWithPrescription/store/slice'
import { CampaignPageReducer } from '../pages/campaign/strore/slice'
import { GiftDetailPageReducer } from '../pages/giftDetail/store/slice'
import { GiftListReducer } from '../pages/giftList/store/slice'
import postApi from './services/post'
import commonApi from './services/common'
import brandApi from './services/brand'
import authApi from './services/auth'
import AutSlice from './services/auth/auth-slice'
import userAddressApi from './services/user/userAddress'
import userWishListApi from './services/user/userProductWishList'
import productDetailApi from './services/productDetail'
import cartApi from './services/cart'
import orderApi from './services/order'
import homeApi from './services/home'
import { UserProductScreenReducer } from '../pages/userProduct/store/slice'
import userFavoriteApi from './services/user/userProductFavorite'
import { BrandDetailReducer } from '../pages/brandDetail/store/slice'
import userReviewApi from './services/userReview'
import { PostPrescriptionReducer } from '../pages/postPrescription/store/slice'
import prescriptionApi from './services/prescription'
import orderReviewApi from './services/orderReview'
import searchDugApi from './services/searchDrug'
import voucherApi from './services/voucher'
import categoryApi from './services/category'
import commentApi from './services/comment'
import userNotifyApi from './services/user/userNotify'
import { UserNotifyScreenReducer } from '../pages/userNotify/store/slice'
import pointApi from './services/point'
import { PointPageReducer } from '../pages/point/store/slice'
import regularScheduleApi from './services/regularSchedule'
import homeSearchApi from './services/homeSearch'
import { SearchResultReducer } from '../pages/searchResult/store/slice'
import { ReturnOrderReducer } from '../pages/returnOrder/store/slice'
import bankApi from './services/bank'
import userBuyWithDealListApi from './services/user/buyWithDeal'
import popupApi from './services/popup'
import contactApi from './services/contact'
import brandVideoApi from './services/brandVideo'

const rootReducer = combineReducers({
  store: StoreReducer,
  homePage: HomePageReducer,
  homeSearch: HomeSearchReducer,
  productDetail: ProductDetailReducer,
  userScreen: UserScreenReducer,
  orderScreen: OrderScreenReducer,
  voucherPage: VoucherPageReducer,
  voucherPageCart: VoucherPageCartReducer,
  cartPage: CartReducer,
  newsPage: NewsPageReducer,
  postSlice: PostDetailReducer,
  prescriptionsPage: PrescriptionsPageReducer,
  addressListPage: AddressListReducer,
  addressForm: AddressFormReducer,
  addressDetail: AddressDetailReducer,
  createOrderPage: CreatOrderReducer,
  cancelOrderPage: CancelOrderReducer,
  editRegularItemPage: EditRegularItemReducer,
  orderReviewDetailPage: OrderReviewDetailReducer,
  tagDetailPage: TagDetailPageReducer,
  buyWithPrescriptionPage: BuyWithPrescriptionReducer,
  campaignPage: CampaignPageReducer,
  giftDetailPage: GiftDetailPageReducer,
  giftListPage: GiftListReducer,
  userProductScreen: UserProductScreenReducer,
  brandDetailPage: BrandDetailReducer,
  postPrescriptionPage: PostPrescriptionReducer,
  pointPage: PointPageReducer,
  user: AutSlice,
  userNotifyScreen: UserNotifyScreenReducer,
  searchResult: SearchResultReducer,
  returnOrderPage: ReturnOrderReducer,
  [postApi.reducerPath]: postApi.reducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [brandApi.reducerPath]: brandApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [userAddressApi.reducerPath]: userAddressApi.reducer,
  [userWishListApi.reducerPath]: userWishListApi.reducer,
  [productDetailApi.reducerPath]: productDetailApi.reducer,
  [cartApi.reducerPath]: cartApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [homeApi.reducerPath]: homeApi.reducer,
  [userFavoriteApi.reducerPath]: userFavoriteApi.reducer,
  [userReviewApi.reducerPath]: userReviewApi.reducer,
  [prescriptionApi.reducerPath]: prescriptionApi.reducer,
  [orderReviewApi.reducerPath]: orderReviewApi.reducer,
  [searchDugApi.reducerPath]: searchDugApi.reducer,
  [voucherApi.reducerPath]: voucherApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [commentApi.reducerPath]: commentApi.reducer,
  [userNotifyApi.reducerPath]: userNotifyApi.reducer,
  [pointApi.reducerPath]: pointApi.reducer,
  [regularScheduleApi.reducerPath]: regularScheduleApi.reducer,
  [homeSearchApi.reducerPath]: homeSearchApi.reducer,
  [bankApi.reducerPath]: bankApi.reducer,
  [userBuyWithDealListApi.reducerPath]: userBuyWithDealListApi.reducer,
  [popupApi.reducerPath]: popupApi.reducer,
  [contactApi.reducerPath]: contactApi.reducer,
  [brandVideoApi.reducerPath]: brandVideoApi.reducer,
})

export type AppState = ReturnType<typeof rootReducer>
export default rootReducer
