import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ROOT, network } from '../../../constant/network'
import { ResponseData } from '../../../types'
import { axiosBaseQuery } from '../../hooks'
import { ContactType } from '../../../types/contactType'

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (build) => ({
    createContact: build.mutation<ResponseData<ContactType>, unknown>({
      query: (param: any) => ({
        url: network.contact.create,
        method: 'post',
        data: param,
      }),
      transformResponse: (rawResult: ResponseData<ContactType>) => {
        return rawResult
      },
    }),
  }),
})

export const { useCreateContactMutation } = contactApi

export default contactApi
